import { FC } from "react";

import Wrapper from "@/components/wrapper";
import Button from "@/components/button";
import IconWrapper from "@/components/icon-wrapper";

const Figma: FC = () => {
  return (
    <Wrapper>
      <>
        <IconWrapper src="/figma.png" alt="Figma" />
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-bold leading-tight">Figma plugins</h4>
          <p className="text-zinc-400 font-normal leading-tight">
            I build plugins to accelerate workflows and automate tasks
          </p>
        </div>
        <div>
          <Button href="/figma">My figma plugins</Button>
        </div>
      </>
    </Wrapper>
  );
};

export default Figma;
