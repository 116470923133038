import React, { FC } from "react";
import Image, { StaticImageData } from "next/image";
import { cn } from "@nextui-org/theme";

interface Props {
  src: string | StaticImageData;
  alt: string;
  size?: number;
  className?: string;
}

const IconWrapper: FC<Props> = ({ alt, src, size = 48, className }) => {
  return (
    <>
      <Image
        width={size}
        height={size}
        src={src}
        alt={alt}
        className={cn("border border-zinc-50/10 rounded-xl", className)}
        quality={100}
      />
    </>
  );
};

export default IconWrapper;
