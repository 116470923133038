import { cn } from "@nextui-org/theme";
import { FC, ReactElement } from "react";

interface Props {
  alt?: string;
  children: ReactElement;
  className?: string;
}

const Wrapper: FC<Props> = ({ alt, children, className }) => {
  return (
    <section
      className={cn(
        "flex flex-col gap-4 border border-zinc-50/10 rounded-[2rem] p-8 overflow-hidden relative",
        className,
      )}
    >
      <h1 className="hidden">{alt}</h1>
      {children}
    </section>
  );
};

export default Wrapper;
