import { FC } from "react";

import { Coffee } from "@/icons";
import socialUrls from "@/components/social-urls";
import Wrapper from "@/components/wrapper";
import Button from "@/components/button";

const BuyMeCoffee: FC = () => {
  return (
    <Wrapper>
      <>
        <div className="size-12 text-3xl bg-zinc-900 border border-zinc-50/10 rounded-xl flex items-center justify-center">
          <Coffee />
        </div>

        <h4 className="text-2xl font-bold leading-tight">
          Support me with a coffee
        </h4>

        <div>
          <Button href={socialUrls.buyCoffee}>Support</Button>
        </div>
      </>
    </Wrapper>
  );
};

export default BuyMeCoffee;
