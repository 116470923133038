import { FC } from "react";

import socialUrls from "@/components/social-urls";
import Button from "@/components/button";
import Wrapper from "@/components/wrapper";
import IconWrapper from "@/components/icon-wrapper";

const DarkVader: FC = () => {
  return (
    <Wrapper>
      <>
        <IconWrapper src="/dark-vader-public.png" alt="Dark Vader icon" />
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-bold leading-tight">
            Dark Vader for Figma
          </h4>
          <p className="text-zinc-400 font-normal leading-tight">
            Manage multi-themed design systems with Dark Vader on Figma
          </p>
        </div>
        <div>
          <Button href={socialUrls.darkVaderPlugin}>Get Dark Vader</Button>
        </div>
      </>
    </Wrapper>
  );
};

export default DarkVader;
