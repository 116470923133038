const socialUrls = {
  twitter: "https://twitter.com/zerox_me",
  dribbble: "https://dribbble.com/zero",
  soundcloud: "https://soundcloud.com/zeroxme",
  github: "https://github.com/zeroxme",
  instagram: "http://instagram.com/zerox_me",
  linkedin: "https://www.linkedin.com/in/zeroxme",
  medium: "https://medium.com/@zerox",
  buyCoffee: "https://www.buymeacoffee.com/zero",
  darkVaderPlugin: "https://darkvader.zerox.me/",
};

export default socialUrls;
